import { PREFIX } from "../constants";

export default function deviceGroups(
  state = {
    deviceGroupItems: {
      parent: {},
      subgroups: [],
    },
    account_groups: [],
    byId: null,
    currentDeviceGroup: null,
    hierarchy: { 1: [] },
    visible_group_ids: [],
  },
  action
) {
  switch (action.type) {
    case "REQUEST_DEVICE_GROUP":
      return Object.assign({}, state, {
        currentDeviceGroup: null,
      });
    case "RECEIVE_DEVICE_GROUP":
      return Object.assign({}, state, {
        currentDeviceGroup: action.payload.device_group,
        visible_group_ids: action.payload.ids_to_display,
        hierarchy: { ...state.hierarchy, ...action.payload.sorted_by_parent },
        byId: !state.byId
          ? action.payload.ById
          : { ...state.byId, ...action.payload.ById },
      });
    case "RECEIVE_ALL_DEVICE_GROUPS":
      return Object.assign({}, state, {
        accountGroups: action.payload,
      });
    case "RECEIVE_CUSTOMERS":
      return Object.assign({}, state, {
        customers:
          // PREFIX === "dc" ? add_super_to_device_groups(action) : action.payload,
          add_super_to_device_groups(action),
        receivedAt: Math.floor(new Date() / 1000),
      });
    case "RECEIVE_CUSTOMER_GROUPS":
      return Object.assign({}, state, {
        deviceGroupItems: separate_groups(action.payload),
      });

    default:
      return state;
  }
}

function add_super_to_device_groups(action) {
  const merged = [
    {
      name: "All Accounts",
      account_id: PREFIX.toUpperCase() + "_SUPER",
    },
    ...action.payload,
  ];
  return merged;
}

function separate_groups(groups) {
  var obj = { parent: groups[0], subgroups: groups[0].subGroups };
  delete obj.parent.subGroups;
  return obj;
}
