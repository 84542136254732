import { RSAA } from "redux-api-middleware";
import { APIURL } from "../constants";
import { apiQuery } from "../libs/auth-config";
import { PREFIX, STAGE } from "../constants";

export const REQUEST = "REQUEST_DEVICE_GROUP";
export const RECEIVE = "RECEIVE_DEVICE_GROUP";
export const FAILURE = "FAILURE_DEVICE_GROUP";
export const REQUEST_DEVICE = "REQUEST_DEVICE";
export const RECEIVE_DEVICE = "RECEIVE_DEVICE";
export const FAILURE_DEVICE = "FAILURE_DEVICE";

export function initRequestDeviceGroup(id) {
  console.log("device group id " + id);
  id = id || 1;
  return {
    [RSAA]: {
      endpoint: APIURL + "/api/v1/device_groups/group/" + id,
      method: "GET",
      types: [
        {
          type: REQUEST,
          // meta: { 'goods': goods }
        },
        RECEIVE,
        FAILURE,
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    },
  };
}

export const REQUEST_DEVICE_GROUPS = "REQUEST_DEVICE_GROUPS";
export const RECEIVE_DEVICE_GROUPS = "RECEIVE_DEVICE_GROUPS";
export const FAILURE_DEVICE_GROUPS = "FAILURE_DEVICE_GROUPS";

export function initRequestAllDeviceGroups(parent_id) {
  var filter = parent_id ? "?filter=parentId:" + parent_id : "";
  return {
    [RSAA]: {
      endpoint: APIURL + "/api/v1/device_groups" + filter,
      method: "GET",
      types: [
        {
          type: REQUEST_DEVICE_GROUPS,
          // meta: { 'goods': goods }
        },
        RECEIVE_DEVICE_GROUPS,
        FAILURE_DEVICE_GROUPS,
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    },
  };
}

export function initRequestDevicesForGroup(id) {
  return {
    [RSAA]: {
      endpoint: APIURL + "/groups/" + id + "/devices",
      method: "GET",
      types: [
        {
          type: REQUEST_DEVICE,
          // meta: { 'goods': goods }
        },
        RECEIVE_DEVICE,
        FAILURE_DEVICE,
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    },
  };
}

export const getSubDeviceGroups =
  (parentId = 0) =>
  (dispatch) => {
    dispatch({ type: "REQUEST_ALL_DEVICE_GROUPS" });

    apiQuery("GET", "/devicegroups", {
      account: PREFIX,
      stage: STAGE,
      ...(parentId && {
        parentId: parentId,
      }),
    })
      .then((data) =>
        dispatch({
          type: "RECEIVE_ALL_DEVICE_GROUPS",
          payload: data.data,
        })
      )
      .catch((e) => {
        dispatch({ type: "FAILED_ALL_DEVICE_GROUPS" });
        console.log(e);
      });
  };

export const getAccountSubgroups = (account_id) => (dispatch) => {
  dispatch({ type: "REQUEST_CUSTOMER_GROUPS" });
  apiQuery("GET", "/devicegroups/subgroups", { account_id: account_id })
    .then((data) =>
      dispatch({ type: "RECEIVE_CUSTOMER_GROUPS", payload: data.data })
    )
    .catch((e) => {
      console.log(e);
      dispatch({ type: "FAILED_CUSTOMER_GROUPS" });
    });
};
