import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Divider, Paper, Tabs, Tab, Grid } from "@material-ui/core";
import DeviceSummary from "../components/DeviceSummary";
import SummaryStat from "../components/SummaryStat";
import OverviewStats from "../components/OverviewMetrics";
import GraphsContainer from "./GraphsContainer";
import DeviceProperties from "../components/DeviceProperties";
import TabPanel from "../components/TabPanel";
import DeviceAlertsTable from "./DeviceAlertsTable";
import SNMPStatusContainer from "./SNMPStatusContainer";

import {
  retrieve_graph_data,
  initRequestWidgetData,
} from "../actions/dashboard";
import { getDeviceUptime } from "../actions/devices";
import { apiQuery } from "../libs/auth-config";
import { Alert } from "@material-ui/lab";
import { CAPABILITY_LEVELS } from "../constants";
import useRelevantPermissions from "../libs/useRelevantPermissions";

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(1),
      flexGrow: 1,
      "& .MuiBox-root": {
        paddingTop: 20,
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    infoButton: {
      height: "35px",
      //marginTop: 10,
      //marginRight: 20,
    },
    gap: {
      marginTop: theme.spacing(1),
    },
    // alert: {
    //   marginBottom: theme.spacing(2),
    // },
  });

class DeviceContainer extends Component {
  abortController = new AbortController();

  constructor(props) {
    super(props);
    const Device = this.props.device;
    this.state = {
      is_hidden: true,
      loadingWidgets: true,
      selected_tab: 0,
      dashboard_id: Device["Device Dashboard ID"],
      tickets: [],
      getting_tickets: false,
      sla: undefined,
      overviewStats: [],
      alerts: [],
      status: "",
      message: "",
      data: [],
      snmpNotConnected: false,
      unableToFetchSNMPInterfaces: false,
      unableToFetchSNMPInfo: false,
      snmpData: {},
      interfaceData: [],
      tmp: false,
      submitState: false,
    };
    this.timer = null;
    this.account = props.account_id;
    this.accountGroups = props.accountGroups;
  }

  componentDidMount() {
    var Device = this.props.device;

    if (!Device["Device Uptime"])
      this.props.getDeviceUptime(this.props.account_id, Device.id);

    var graphs = this.props.graphsById
      ? this.props.graphsById[this.state.dashboard_id]
      : null;
    if (!graphs) {
      this.updateGraphRanges(this.state.dashboard_id);
    }

    // ranges don't change on mount - we just simulate that.
    this.setState({
      sla: undefined,
      getting_tickets: true,
      //loadingWidgets: false,
      rangesChanged: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fromEpoch !== prevProps.fromEpoch ||
      this.props.toEpoch !== prevProps.toEpoch ||
      this.props.dateType !== prevProps.dateType ||
      (this.props.forceUpdate && !prevProps.forceUpdate)
    ) {
      this.updateGraphRanges(this.state.dashboard_id);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    this.setState({
      sla: undefined,
    });
  }

  /* 
  Store diagnostics data here so that it doesn't have to reload
  every time you click back on diagnostics tab.
  */
  setSnmpData = (data) => {
    this.setState({ snmpData: data });
  };

  setInterfaceData = (data) => {
    this.setState({ interfaceData: data });
  };

  setUnableToFetchSNMPInterfaces = (status) => {
    this.setState({ unableToFetchSNMPInterfaces: status });
  };

  setUnableToFetchSNMPInfo = (status) => {
    this.setState({ unableToFetchSNMPInfo: status });
  };

  setDateRangeValues = () => {
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

    this.handleFromDateChange(yesterday);
    this.handleToDateChange(today);
  };

  updateGraphRanges = async (dashboard_id, type) => {
    if (!dashboard_id) {
      this.setState({
        loadingWidgets: false,
        report_error: true,
        alerts: this.props.device_alerts,
        data: [],
      });
      return;
    }
    const range = {
      from: this.props.fromEpoch,
      to: this.props.toEpoch,
    };

    this.setState({ loadingWidgets: true, report_error: false, data: [] });
    apiQuery("GET", "/dashboards/dashboard/data", {
      account_id: this.props.account_id,
      dash_id: dashboard_id,
      start: range.from,
      end: range.to,
    })
      .then((response) => {
        if (!response || response.error) {
          this.setState({
            loadingWidgets: false,
            rangesChanged: true,
            report_error: true,
          });
        } else {
          this.setState({
            data: response.data
              .filter(
                (widget) =>
                  widget.type === "graph" &&
                  !widget.title.includes("Summary Stats")
              )
              .map((widget) => ({ ...widget, table: false })),
          });
          for (const id in response.data) {
            if (
              response.data[id].title === "Availability" &&
              response.data[id].resultList
            ) {
              // cache the sla from the response in state.
              this.setState({
                sla: response.data[id].resultList[0].value,
              });
            }
            if (response.data[id].title.includes("Summary Stats")) {
              this.setState({
                overviewStats: response.data[id].lines,
              });
            }
            if (
              response.data[id].title === "Alerts" &&
              response.data[id].items
            ) {
              // Merge historical and current alerts.
              let device_alerts = this.props.device_alerts;
              for (var index in response.data[id].items) {
                var found = false;
                var item = response.data[id].items[index];
                // eslint-disable-next-line
                device_alerts.forEach((alert) => {
                  if (alert.id === item.id) {
                    found = true;
                  }
                });
                if (!found) device_alerts.push(item);
              }

              var finalAlerts = [];
              // eslint-disable-next-line
              device_alerts.forEach((alert) => {
                // console.log(alert);
                var alertId = alert.monitorObjectId;
                let newDevicesInfo = this.props.devicesInfo.byId[alertId];
                let mergedData = { ...newDevicesInfo, ...alert };
                finalAlerts.push(mergedData);
              });

              this.setState({
                alerts: finalAlerts,
              });
            }
          }
          this.setState({ loadingWidgets: false, rangesChanged: false });
        }
      })
      .catch((e) => {
        this.setState({
          loadingWidgets: false,
          rangesChanged: true,
          report_error: true,
        });
      })
      .finally(() => this.props.completeUpdate());
  };

  handleError = (status, message) => {
    this.setState({ status: status, message: message });
  };

  render() {
    const { classes, relevantPermissions } = this.props;
    var Device = this.props.device;

    var dashboard_type = Device["Type"] || "default";
    var tabIndex = 0,
      tabPanelIndex = 0;

    var sla_text =
      this.state.sla && this.state.sla !== "Empty Data"
        ? (Math.round(this.state.sla * 100) / 100).toFixed(2) + "%"
        : "-";

    let visibleDashboardFolders = Object.entries(this.props.dashboard_map)
      .map(([_, dashboard]) => dashboard.folder)
      .filter((folder) => {
        // Can break based on config file.
        try {
          return (
            relevantPermissions.services[folder.toLowerCase()] <=
            CAPABILITY_LEVELS["view"]
          );
        } catch (e) {
          return false;
        }
      });

    return (
      <div>
        <div className={classes.alert}>
          {this.state.message && (
            <Alert severity="error">
              Status: {this.state.status}, Message: {this.state.message}
            </Alert>
          )}
        </div>
        <DeviceSummary
          account={this.account}
          Device={Device}
          superuser={this.props.is_superuser}
          tickets={this.state.tickets}
          getting_tickets={this.state.getting_tickets}
          callback={this.getTickets}
        />
        <Grid container spacing={1}>
          {this.props.dashboard_config[dashboard_type].availability ? (
            <SummaryStat
              noicon
              tile={{
                title: "Availability",
                count: sla_text,
              }}
            />
          ) : null}
          <OverviewStats
            lineData={this.state.overviewStats}
            type={dashboard_type}
          />
        </Grid>
        <Paper className={classes.gap}>
          <Tabs
            indicatorColor="primary"
            value={this.state.selected_tab}
            onChange={(event, newValue) => {
              var dashboard_id_name;
              try {
                dashboard_id_name =
                  this.props.dashboard_map[event.target.innerText]["property"];
              } catch (err) {
                dashboard_id_name = null;
              }
              if (dashboard_id_name) {
                this.updateGraphRanges(Device[dashboard_id_name]);
              }
              this.setState({
                selected_tab: newValue,
              });
              if (Device[dashboard_id_name]) {
                this.setState({
                  dashboard_id: Device[dashboard_id_name],
                });
              }
            }}
            aria-label="provisioning_tabs"
          >
            {Object.keys(this.props.dashboard_map).map((dash_name) => {
              if (
                (Device[this.props.dashboard_map[dash_name]["property"]] ||
                  this.props.dashboard_map[dash_name]["default"]) &&
                (visibleDashboardFolders.includes(
                  this.props.dashboard_map[dash_name]["folder"]
                ) ||
                  this.props.dashboard_map[dash_name]["default"])
              ) {
                return <Tab label={dash_name} {...a11yProps(tabIndex++)} />;
              } else {
                return null;
              }
            })}
            {relevantPermissions.services.alerts <=
              CAPABILITY_LEVELS["view"] && (
              <Tab label={"Alerts"} {...a11yProps(tabIndex++)} />
            )}
            {relevantPermissions.services.diagnostics <=
              CAPABILITY_LEVELS["view"] && (
              <Tab label={"Diagnostics"} {...a11yProps(tabIndex++)} />
            )}
            {(relevantPermissions.services.systemProperties <=
              CAPABILITY_LEVELS["view"] ||
              relevantPermissions.services.customProperties <=
                CAPABILITY_LEVELS["view"]) && (
              <Tab label={"Properties"} {...a11yProps(tabIndex++)} />
            )}
          </Tabs>
          <Divider />
          {Object.keys(this.props.dashboard_map).map((dash_name, idx) => {
            if (
              (Device[this.props.dashboard_map[dash_name]["property"]] ||
                this.props.dashboard_map[dash_name]["default"]) &&
              (visibleDashboardFolders.includes(
                this.props.dashboard_map[dash_name]["folder"]
              ) ||
                this.props.dashboard_map[dash_name]["default"])
            ) {
              return (
                <TabPanel
                  value={this.state.selected_tab}
                  index={tabPanelIndex++}
                >
                  <GraphsContainer
                    overviewStats={this.state.overviewStats}
                    report_error={this.state.report_error}
                    isUpdating={this.state.loadingWidgets}
                    Device={Device}
                    updateWidgetState={(i) =>
                      this.setState({
                        data: this.state.data.map((elm, j) =>
                          j === i ? { ...elm, table: !elm.table } : elm
                        ),
                      })
                    }
                    dashboard_id={
                      Device[this.props.dashboard_map[dash_name]["property"]]
                    }
                    bigGraph={this.props.dashboard_map[dash_name]["bigGraph"]}
                    data={this.state.data}
                    onFullScreen={() =>
                      this.setState({
                        rangesChanged: true,
                        tmp: this.state.rangesChanged,
                      })
                    }
                    exitFullScreen={() =>
                      this.setState({ rangesChanged: this.state.tmp })
                    }
                    fromEpoch={this.props.fromEpoch}
                    toEpoch={this.props.toEpoch}
                    dateType={this.props.dateType}
                  />
                </TabPanel>
              );
            } else {
              return null;
            }
          })}
          {relevantPermissions.services.alerts <= CAPABILITY_LEVELS["view"] && (
            <TabPanel value={this.state.selected_tab} index={tabPanelIndex++}>
              <DeviceAlertsTable title={"Alerts"} items={this.state.alerts} />
            </TabPanel>
          )}
          {relevantPermissions.services.diagnostics <=
            CAPABILITY_LEVELS["view"] && (
            <TabPanel value={this.state.selected_tab} index={tabPanelIndex++}>
              <SNMPStatusContainer
                accountId={this.props.account_id}
                snmpData={this.state.snmpData}
                setSnmpData={this.setSnmpData}
                interfaceData={this.state.interfaceData}
                setInterfaceData={this.setInterfaceData}
                unableToFetchSNMPInfo={this.state.unableToFetchSNMPInfo}
                setUnableToFetchSNMPInfo={this.setUnableToFetchSNMPInfo}
                unableToFetchSNMPInterfaces={
                  this.state.unableToFetchSNMPInterfaces
                }
                setUnableToFetchSNMPInterfaces={
                  this.setUnableToFetchSNMPInterfaces
                }
                device={Device}
                title={"Diagnostics"}
                style={{ display: "table" }}
              />
            </TabPanel>
          )}
          {(relevantPermissions.services.systemProperties <=
            CAPABILITY_LEVELS["view"] ||
            relevantPermissions.services.customProperties <=
              CAPABILITY_LEVELS["view"]) && (
            <TabPanel value={this.state.selected_tab} index={tabPanelIndex++}>
              <DeviceProperties
                device={Device}
                allDevices={this.props.byId}
                callback={(status, message) =>
                  this.handleError(status, message)
                }
              />
            </TabPanel>
          )}
        </Paper>
      </div>
    );
  }
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function mapStateToProps(state, ownProps) {
  const { widgets } = state.dashboard;
  const { byId } = state.devices;
  const { is_superuser } = state.userSession;
  const { account_id } = state.userSession.properties;
  const deviceAlertIds = state.alerts.byDeviceId[ownProps.device.id];
  const alertIds = deviceAlertIds ? deviceAlertIds : [];
  const { role } = state.userSession || "reseller";
  const { dashboard_map, dashboard_config } =
    state.ui_settings.portal_config || {};
  const { accountGroups } = state.deviceGroups;
  const { rootFolderId } = state.ui_settings.portal_config;

  var sla;
  widgets.forEach((widget, i) => {
    if (widget.title === "Availability" && widget.resultList) {
      sla = widget.resultList[0].value;
    }
  });

  let devicesInfo = state.devices || [];

  const device_alerts = [];
  try {
    alertIds.forEach((id) => {
      device_alerts.push(state.alerts.byId[id]);
    });
  } catch (e) {}

  return {
    sla,
    widgets,
    device_alerts,
    is_superuser,
    account_id,
    // alerts,
    devicesInfo,
    byId,
    role,
    dashboard_map,
    dashboard_config,
    accountGroups,
    rootFolderId,
  };
}

const withRelevantPermissions = (Component) => {
  return function WrappedComponent(props) {
    const relevantPermissions = useRelevantPermissions();
    return <Component {...props} relevantPermissions={relevantPermissions} />;
  };
};

const mapDispatchToProps = {
  retrieve_graph_data,
  initRequestWidgetData,
  getDeviceUptime,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(withRelevantPermissions(DeviceContainer));
