import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAlertThresholdConfig,
  getDatasources,
  getInstances,
} from "../actions/alerts";
import { HAS_RESELLERS } from "../constants";
// import { RbacContext } from "../containers/LoadingContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
    "& .MuiToolbar-root": {
      display: "none",
    },
    "& .Mui-selected": { backgroundColor: "transparent" },
  },
  tableRow: {},
  auto: {
    width: "100%",
  },
  radio: {
    marginBottom: theme.spacing(1),
  },
  thresholdField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    borderColor: "#ff1100",
  },
  check: {
    cursor: "auto",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));

export default function ThresholdConfiguration({
  account_id,
  setButtonState,
  startExecution,
  saveUpdates,
  savePrompt,
  resetUpdateValues,
  resetSaveState,
}) {
  const [radio, setRadio] = React.useState(
    HAS_RESELLERS ? "Reseller" : "Customer"
  );
  const [target, setTarget] = React.useState("");
  const deviceList = useSelector((state) => state.deviceSearch.Devices);
  const customerList = useSelector((state) =>
    HAS_RESELLERS
      ? state.deviceGroups.deviceGroupItems.subgroups
      : [state.deviceGroups.deviceGroupItems.parent]
  );
  const resellerList = useSelector((state) => [
    state.deviceGroups.deviceGroupItems.parent,
  ]);
  const [activeList, setActiveList] = React.useState(
    HAS_RESELLERS ? resellerList : customerList
  );
  const [running, setRunning] = React.useState(false);
  const [datasourceList, setDatasourceList] = React.useState([]);
  const [datasource, setDatasource] = React.useState("");
  const [instanceList, setInstanceList] = React.useState([]);
  const [instance, setInstance] = React.useState("");
  const [config, setConfig] = React.useState([]);
  const classes = useStyles();
  // const manage = useContext(RbacContext).Alerts["Configure Alerts"].manage;

  const autoMap = {
    Reseller: () => {
      setActiveList(resellerList);
      setTarget(resellerList[0]);
    },
    Customer: () => {
      setActiveList(customerList);
      if (!HAS_RESELLERS) setTarget(customerList[0]);
    },
    Device: () => setActiveList(deviceList),
  };

  useEffect(() => {
    autoMap[radio]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio]);

  useEffect(() => {
    if (target) {
      setRunning(true);
      getDatasources(account_id, radio, target.id)
        .then((data) => setDatasourceList(data))
        .catch((e) => console.log(e))
        .finally(() => setRunning(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  useEffect(() => {
    if (datasource) {
      setRunning(true);
      if (radio === "Device") {
        getInstances(account_id, radio, target.id, datasource.id)
          .then((data) =>
            data.length > 1 ? setInstanceList(data) : setInstance(data[0])
          )
          .catch((e) => console.log(e));
      } else
        setInstance({ ...datasource, name: datasource.dataSourceDisplayName });
      setRunning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasource]);

  useEffect(() => {
    if (instance) {
      let id;
      if (radio === "Device") id = datasource.id;
      else id = datasource.dataSourceId;
      setRunning(true);
      getAlertThresholdConfig(account_id, radio, target.id, id, instance.id)
        .then((data) => setConfig(data))
        .catch((e) => console.log(e))
        .finally(() => setRunning(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  useEffect(() => {
    if (config.length) checkStateValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if (savePrompt) saveConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePrompt]);

  const columns = [
    {
      name: "disableAlerting",
      label: "Enabled",
      options: {
        customBodyRenderLite: (i) => (
          <Checkbox
            color="primary"
            checked={
              !config[i].disableAlerting && !config[i].alertingDisabledOn
            }
            // className={!manage ? classes.check : null}
            disabled={
              config[i].alertExpr
                ? !checkValidity(config[i].alertExpr)
                : config[i].globalAlertExpr
                ? !checkValidity(config[i].globalAlertExpr)
                : true
            }
            disableRipple
            onClick={(e) => updateThreshold(!e.target.checked, i)}
          />
        ),
      },
    },
    { name: "dataPointName", label: "Datapoint" },
    { name: "dataPointDescription", label: "Description" },
    {
      name: "alertExpr",
      label: "Threshold",
      options: {
        customBodyRenderLite: (i) => (
          <TextField
            label={
              config[i].alertExpr
                ? "custom"
                : config[i].globalAlertExpr
                ? "global"
                : null
            }
            defaultValue={
              config[i].alertExpr
                ? config[i].alertExpr
                : config[i].globalAlertExpr
            }
            className={
              checkValidity(config[i].alertExpr) ? null : classes.thresholdField
            }
            // disabled={!manage}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => updateThreshold(e.target.value.trim(), i)}
          />
        ),
      },
    },
  ];

  const checkValidity = (expr) => {
    if (!expr || expr.match(/^(?:=|!=|<=?|>=?) \d+(?: \d+){0,2}$/)) return true;
  };

  const checkStateValidity = () => {
    for (let i = 0; i < config.length; i++) {
      if (!checkValidity(config[i].alertExpr)) return setButtonState(true);
    }
    setButtonState(false);
  };

  const updateThreshold = (val, i) => {
    setConfig(
      [...config],
      typeof val === "boolean"
        ? (config[i].disableAlerting = val)
        : (config[i].alertExpr = config[i].globalAlertExpr === val ? "" : val)
    );
    if (radio === "Device")
      saveUpdates(
        {
          dataPointId: config[i].dataPointId,
          alertExprNote: config[i].alertExprNote,
          ...(typeof val === "boolean" && {
            disableAlerting: val,
            alertExpr: config[i].alertExpr,
          }),
          ...(typeof val === "string" && {
            disableAlerting: config[i].disableAlerting,
            alertExpr: config[i].globalAlertExpr === val ? "" : val,
          }),
        },
        true
      );
    else saveUpdates(config);
  };

  const saveConfiguration = () => {
    setRunning(true);
    startExecution(
      {
        account_id: account_id,
        type: radio,
        id: target.id,
        ds_id: radio === "Device" ? datasource.id : datasource.dataSourceId,
        info: {
          name: target.name || target["Billing Reference"],
          ds_name: datasource.dataSourceDisplayName,
        },
        ...(radio === "Device" && {
          inst_id: instance.id,
        }),
      },
      radio === "Device" ? true : false
    )
      .then(() => setRunning(false))
      .finally(() => resetSaveState());
  };

  const handleRadioChange = (e) => {
    setTarget("");
    setRadio(e.target.value);
  };

  const handleAutoChange = (val) => {
    setConfig([]);
    setDatasourceList([]);
    setDatasource("");
    setInstanceList([]);
    setInstance("");
    resetUpdateValues();
    setTarget(val);
  };

  const handleDatasourceChange = (val) => {
    setConfig([]);
    setInstanceList([]);
    setInstance("");
    resetUpdateValues();
    setDatasource(val);
  };

  const handleInstanceChange = (val) => {
    setConfig([]);
    resetUpdateValues();
    setInstance(val);
  };

  const getLabel = (val) => {
    if (radio !== "Device") return val.name;
    else
      try {
        return val["Billing Reference"].replace("DR-", "");
      } catch (e) {
        return val["DNS Name"];
      }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <RadioGroup row value={radio} className={classes.radio}>
        {HAS_RESELLERS && (
          <FormControlLabel
            value="Reseller"
            label="Reseller"
            control={<Radio onClick={handleRadioChange} />}
          />
        )}
        <FormControlLabel
          value="Customer"
          label="Customer"
          control={<Radio onClick={handleRadioChange} />}
        />
        <FormControlLabel
          value="Device"
          label="Device"
          control={<Radio onClick={handleRadioChange} />}
        />
      </RadioGroup>
      <Grid container spacing={1}>
        <Grid item lg={6}>
          <Autocomplete
            options={activeList}
            getOptionLabel={(option) => getLabel(option)}
            freeSolo
            disabled={
              radio === "Reseller" || (!HAS_RESELLERS && radio === "Customer")
                ? 1
                : 0
            }
            className={classes.auto}
            value={target}
            onChange={(_, v) => handleAutoChange(v)}
            renderInput={(params) => (
              <TextField
                key={params.id}
                {...params}
                label={radio}
                value={target}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item lg={6}>
          <Autocomplete
            options={datasourceList}
            getOptionLabel={(option) => option.dataSourceDisplayName}
            freeSolo
            disabled={!datasourceList.length || !target}
            value={datasource}
            onChange={(_, v) => v && handleDatasourceChange(v)}
            renderInput={(params) => (
              <TextField
                className={`${classes.auto} ${classes.radio}`}
                key={params.id}
                {...params}
                label="DataSource"
                variant="outlined"
                size="small"
              />
            )}
          />

          <Autocomplete
            options={instanceList}
            getOptionLabel={(option) =>
              option.displayName ? option.displayName : option.name
            }
            freeSolo
            disabled={!instanceList.length || !datasource.isMultiple || !target}
            onChange={(_, v) => v && handleInstanceChange(v)}
            value={instance}
            renderInput={(params) => (
              <TextField
                key={params.id}
                {...params}
                className={classes.auto}
                value={instance}
                label="Instance"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
      </Grid>
      <MUIDataTable
        columns={columns}
        data={config}
        options={{
          selectableRows: "multiple",
          rowsSelected: config.map((val, i) =>
            !val.disableAlerting ? i : null
          ),
          isRowSelectable: (i) =>
            config[i].alertExpr || config[i].globalAlertExpr ? true : false,
          selectToolbarPlacement: "none",
          selectableRowsHideCheckboxes: true,
          print: false,
          fixedSelectColumn: false,
          viewColumns: false,
          selectableRowsHeader: false,
          elevation: 0,
          rowsPerPage: 100,
          textLabels: {
            body: {
              noMatch: running ? (
                <CircularProgress loading={running} />
              ) : !config.length ? (
                "Choose a DataSource to display the threshold configuration."
              ) : (
                "Sorry, there is no matching data to display"
              ),
            },
          },
        }}
      />
    </Paper>
  );
}
