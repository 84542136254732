import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { format, sub } from "date-fns";
import { customReport } from "../actions/reports";
import { withStyles } from "@material-ui/core/styles";
import ReportsTable from "../containers/ReportsTable";
import { apiQuery } from "../libs/auth-config";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "",
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    paddingRight: "50px",
    alignItems: "center",
    top: "30px",
  },
  dateSelection: {
    position: "relative",
  },
  dateGap: {
    marginBottom: 10,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  singleDropdown: {
    width: "500px",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    width: 120,
  },
});

class RunReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportRunning: false,
      tooltipLabel: "Last 24 hours",
      scheduled_reports: [],
      loading: false,
      customNow: 0,
      customThen: 0,
      dateSelection: false,
      runButtonHidden: true,
      selectReport: "availability",
      sliderDisabled: false,
      startDate: sub(new Date(), { days: 1 }),
      endDate: new Date(),
      reportId: "",
      range: "",
      scheduledLoading: false,
      scheduledError: "",
      pdfmessage: "",
      format: "CSV",
      pdfRecipients: "",
      validRecipients: true,
      columns: this.props.report_properties,
      reportsFilters: [{ property: "", operator: null, value: "" }],
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle("Reports");
    this.listReports();
  }

  async listReports() {
    const account_id = this.props.account_id;
    this.setState({ loading: true });
    let reports = [];

    apiQuery("GET", "/reports/scheduled?account_id=" + account_id)
      .then((response) => {
        reports = response["data"];
      })
      .catch((e) => console.log(e))
      .finally(() =>
        this.setState({
          scheduled_reports: reports,
          loading: false,
        })
      );
  }

  componentDidUpdate(prevProps) {
    if (this.props.results !== prevProps.results) {
      this.setState({
        reportRunning: false,
        runButtonHidden: false,
      });
    }
  }

  setSelectReport = (report, prevReport) => {
    const { format } = this.state;
    this.setState({
      selectReport: report,
      runButtonHidden: false,
      dateSelection: false,
      sliderDisabled: false,
      ...(report === "graphicalUtilisation" && {
        prevFormat: format,
        format: "HTML",
      }),
      ...(prevReport === "graphicalUtilisation" && {
        format: this.state.prevFormat,
      }),
    });
    if (report.toLowerCase().includes("inventory")) {
      this.setState({ sliderDisabled: true });
    }
  };

  updateColumns = (cols) => {
    this.setState({ columns: cols });
  };

  executeReport = (
    unique_id,
    time,
    startDate,
    endDate,
    reportName,
    scheduledFormat,
    scheduledReport,
    scheduledColumns,
    recipients,
    filters
  ) => {
    const { items, deviceGroupItems, account_id, Devices } = this.props;
    const { validRecipients } = this.state;
    const reportFormat = scheduledFormat || this.state.format;
    const selectReport = scheduledReport || this.state.selectReport;
    const columns = scheduledColumns || this.state.columns;
    const reportsFilters = filters || this.state.reportsFilters;

    var reportTime, accountName, templateInfo, reportType, reduxKey;
    if (time === "24_Hours") {
      reportTime = "Last 24 hours";
      reportType = "24_Hours";
    } else if (time === "Week") {
      reportTime = "Last 7 days";
      reportType = "Week";
    } else if (time === "Month") {
      reportTime = "Last 30 days";
      reportType = "Month";
    } else if (time === "Year") {
      reportTime = "Last 365 days";
      reportType = "Year";
    } else {
      reportTime =
        format(startDate, "yyyy-MM-dd HH:mm") +
        " TO " +
        format(endDate, "yyyy-MM-dd HH:mm");
      reportType = time;
    }
    accountName = deviceGroupItems.parent.name;

    for (var i = 0; i < items.length; i++) {
      if (items[i].description.includes(selectReport)) {
        templateInfo = items[i];
        // Scheduled reports dont have the lowercase value saved, so re-split the description to get it
        reduxKey = items[i].description.split("||")[4];
        this.setState({ selectReport: reduxKey });
      }
    }

    if (templateInfo.description.includes("Host Inventory")) {
      reportTime = "Last 24 hours";
      reportType = "24_Hours";
    }

    // If they've selected a PDF metric trend.
    if (
      reportFormat === "HTML" &&
      !templateInfo.description.includes("Host Metric Trend")
    ) {
      return this.setState({
        pdfmessage: "Error: HTML reports are ony available for metric trends.",
      });
    }

    // If they've selected a PDF with no recipients.
    if (reportFormat === "HTML" && !(recipients || this.state.pdfRecipients)) {
      return this.setState({
        pdfmessage: "Error: Recipients required for HTML reports.",
      });
    }

    // Different recipients variable used based on scheduled or on demand.
    let reportRecipients = recipients ? recipients : this.state.pdfRecipients;

    // If they've selected a PDF with invalid emails.
    if (!validRecipients && reportFormat === "HTML")
      return this.setState({
        pdfmessage: "Error: Recipients are not valid.",
      });

    templateInfo.dateRange = reportTime;
    var timeStampEpoch = Date.now();
    const table_id = `${reduxKey}__${reportType}`;

    var newReportName = accountName + reduxKey + time + timeStampEpoch;
    templateInfo.name = newReportName.replaceAll(" ", "");

    var reportsNeeded = Math.ceil(Devices.length / 600);
    this.setState({
      ...(reportFormat !== "HTML" && { reportRunning: true }),
      reportId: table_id,
      range: reportType,
    });
    this.props
      .customReport(
        unique_id,
        table_id,
        account_id,
        templateInfo,
        reportFormat,
        reportRecipients,
        reportTime,
        reportName,
        columns,
        reportsNeeded,
        reportsFilters
      )
      .then((status_code) => {
        this.setState({
          ...(reportFormat === "HTML" && {
            pdfmessage:
              status_code === 200
                ? "Success: The report will be emailed to the recipients. Reports with a large number of devices or high time range may take longer to deliver."
                : "Error: Failed to create HTML report.",
          }),
        });
      });
  };

  saveScheduledReport = (
    time,
    cronSchedule,
    cronReadable,
    reportName,
    update,
    wasScheduled,
    filters
  ) => {
    const { items, account_id } = this.props;
    const { selectReport, columns } = this.state;
    this.setState({ scheduledLoading: true });
    var templateInfo, descriptionSplit, reportNameDB;
    for (var i = 0; i < items.length; i++) {
      if (items[i].description.includes(selectReport)) {
        templateInfo = items[i];
        descriptionSplit = templateInfo.description.split("||");
        reportNameDB = descriptionSplit[5];
      }
    }

    if (
      this.state.format === "HTML" &&
      !templateInfo.description.includes("Host Metric Trend")
    ) {
      this.setState({
        pdfmessage: "Error: HTML reports are ony available for metric trends.",
      });
      throw new Error(
        "Error: HTML reports are ony available for metric trends."
      );
    }

    var data = {
      report_name: reportName,
      schedule: cronSchedule,
      scheduleReadable: cronReadable,
      recipients: this.state.pdfRecipients,
      data: templateInfo,
      time_range: time,
      report_template: reportNameDB,
      report_format: this.state.format,
      account_id: account_id,
      custom_columns: columns,
      filters: filters,
    };

    var output = "";
    return new Promise((resolve, reject) => {
      apiQuery(update ? "PUT" : "POST", "/reports/scheduled", {
        ...data,
        ...(update && { had_schedule: wasScheduled }),
      })
        .then((response) => {
          output = response;
        })
        .catch((e) => {
          console.log(e);
          this.setState({ scheduledError: "Failed", scheduledLoading: false });
          reject();
          //output = {"status_code":500,"message":"Internal Error"}
        })
        .finally(() => {
          this.setState({ scheduledLoading: false });
          if (output) {
            this.setState({ scheduledError: output["message"] });
          }
          this.listReports();
          resolve();
        });
    });
  };

  clearSchedulingError = (msg) => {
    /* 
     Set scheduled error to null when the report confirmation window closes.
    */
    this.setState({ scheduledError: null });
  };

  handleConfigClose = () => {
    this.setState({
      selectReport: "availability",
      scheduledError: "",
      scheduledLoading: false,
      format: "CSV",
      columns: ["Billing Reference", "Postcode"],
      pdfRecipients: "",
    });
  };

  render() {
    const { Devices, items, reports, account_id } = this.props;
    var templateInfo;
    for (var i = 0; i < items.length; i++) {
      if (items[i].description.includes(this.state.selectReport)) {
        templateInfo = items[i];
      }
    }

    return (
      <>
        <ReportsTable
          callBack={this.executeReport}
          templateInfo={templateInfo}
          devices={Devices}
          reportRunning={this.state.reportRunning}
          runButtonDisabled={this.state.runButtonHidden}
          timeDisabled={this.state.sliderDisabled}
          reports={reports}
          loading={this.state.loading}
          selectedReport={this.state.selectReport}
          setReportCallBack={this.setSelectReport}
          format={this.state.format}
          pdfRecipients={this.state.pdfRecipients}
          setPdfStates={(format, emails, valid) =>
            this.setState({
              format: format,
              pdfRecipients: emails,
              validRecipients: valid,
            })
          }
          scheduled_reports={this.state.scheduled_reports}
          callbackSaveReport={this.saveScheduledReport}
          clearSchedulingError={this.clearSchedulingError}
          schedulingError={this.state.scheduledError}
          account_id={account_id}
          scheduledLoading={this.state.scheduledLoading}
          scheduledError={this.state.scheduledError}
          handleConfigClose={this.handleConfigClose}
          updateColumns={this.updateColumns}
          customColumns={this.state.columns}
          updateReportFilters={(filters) =>
            this.setState({ reportsFilters: filters })
          }
          reportFilters={this.state.reportsFilters}
          listReportsCallback={() => this.listReports()}
          updateLoading={(val) => this.setState({ loading: val })}
          reportsFilters={this.state.reportsFilters}
        />
        <Snackbar
          open={this.state.pdfmessage}
          autoHideDuration={10000}
          onClose={() => this.setState({ pdfmessage: "" })}
        >
          <Alert
            onClose={() => this.setState({ pdfmessage: "" })}
            severity={
              this.state.pdfmessage.includes("Success") ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.pdfmessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { byId } = state.devices;
  const { items, results } = state.reports;
  const { deviceGroupItems } = state.deviceGroups;
  const { role } = state.userSession;
  const { account_id } = state.userSession.properties;
  const { report_properties } = state.ui_settings.portal_config || [];

  var reports = [];

  for (var report in items) {
    var reportName = items[report].name;
    if (
      reportName.includes("ADMIN") &&
      role !== "admin" &&
      role !== "superuser"
    ) {
      continue;
    }
    reports.push(items[report]);
  }

  // Only show overview reports for all accounts, instance limit is too low.
  reports = reports.sort((a, b) =>
    a.description.split("||")[5] > b.description.split("||")[5] ? 1 : -1
  );
  if (account_id.includes("SUPER")) {
    reports = reports.filter(
      (r) =>
        r.description.split("||").length > 6 &&
        r.description.split("||")[6] === "SHOW_TO_ALL_ACCOUNTS"
    );
  }

  var Devices = [];
  for (var key in byId) {
    Devices.push(byId[key]);
  }

  return {
    Devices,
    items,
    results,
    deviceGroupItems,
    reports,
    account_id,
    report_properties,
  };
}

const mapDispatchToProps = {
  customReport,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(RunReportContainer);
