import React, { useEffect, useRef, useState } from "react";
import SummaryTile from "./SummaryTile";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Card, Grid } from "@material-ui/core";

export default function SummaryDashboard({
  bySiteTiles,
  tileSize,
  textSize,
  details,
  alignTiles,
  dashboardSettings,
  groupBy,
}) {
  const [width, setWidth] = useState(0);

  React.useEffect(() => {
    return () => {};
  }, []);

  const ref = useRef(null);

  const convertTileSize = (ts, property) => {
    /*
      Centralised definition of how tiles should be sized as ghost
      elements must match this.
     */
    switch (property) {
      case "width":
        return ts * 40;
      case "height":
        return 20 + ts * 10;
      case "bodySize": {
        return 0.5 + ts * 0.1;
      }
      case "titleSize":
        return 0.5 + ts * 0.1;
      default:
        return 0;
    }
  };

  useEffect(() => {
    /* Update width everytime the <Grid container> is resized. */
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });
    const current = ref.current;
    observer.observe(current);
    return () => current && observer.unobserve(current);
  }, []);

  const fixLastRowAlignment = () => {
    /*
     Render ghost elements equal to the number of columns in the
     <Grid container> minus the number of real elements, in the last row
     which forces the real elements to align left.
    */
    let numberOfColumns =
      Math.round(width / convertTileSize(tileSize, "width")) - 1;
    if (!width || Object.keys(bySiteTiles).length < numberOfColumns) {
      // If the component hasn't loaded you don't need to return anything,
      // if there's only a single row (fewer tiles to load than the number of
      // potential columns) you don't need to align the last row either.
      return <></>;
    } else {
      return [...Array(numberOfColumns).keys()].map((_) => (
        <Grid
          item
          style={{
            width: `${convertTileSize(tileSize, "width")}px`,
            height: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            padding: "0px",
          }}
        />
      ));
    }
  };

  //setBySiteTiles(gatherStatusTiles(bySites,alerts))
  return bySiteTiles ? (
    <Card elevation={0} style={{ overflowY: "auto", maxHeight: "100vh" }}>
      <Grid
        container
        justifyContent={alignTiles}
        ref={ref}
        style={{ padding: "20px", paddingTop: "0px", gap: `${3 + tileSize}px` }}
      >
        {Object.keys(bySiteTiles).map((tile) => {
          let info = details
            ? {
                Devices: bySiteTiles[tile]["devices"].length,
                Alerts: bySiteTiles[tile]["AlertTotal"],
              }
            : [];
          var tmpTile = {
            title: tile.trim(), // Sometimes appending a space to the end of tile names in order to fix sorting ID's.
            info: info,
            link: "/services/connectivity?search_term=" + tile,
            status: bySiteTiles[tile]["Severity"],
            borderColor: bySiteTiles[tile]["boxColor"],
          };
          return (
            <SummaryTile
              convertTileSize={convertTileSize}
              key={tmpTile.title}
              tile={tmpTile}
              tileSize={tileSize}
              textSize={textSize}
              details={details}
              groupBy={groupBy}
            />
          );
        })}
        {alignTiles === "center" && fixLastRowAlignment()}
      </Grid>
    </Card>
  ) : (
    <CircularProgress loading={true} />
  );
}
