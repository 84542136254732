import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { compose } from "redux";
import PropTypes from "prop-types";

import { withStyles, createStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  logoutUser,
  fetchCognitoUser,
  getViewRoleConfiguration,
  // getRbac,
} from "../actions/session";
import { getUserPermissions } from "../actions/roles";
import { initRequestAlerts } from "../actions/alerts";
import {
  dispatchChaining,
  listAllDevicesThenAlerts,
  initRequestAllDevices,
  listDevicesThenAlerts,
  // initMinimalMonitoringSearch,
} from "../actions/devices";
import { getUserSettings, initGetPortalConfig } from "../actions/ui_settings";

import {
  initRequestAllDeviceGroups,
  getSubDeviceGroups,
  getAccountSubgroups,
} from "../actions/devicegroup";
import {
  initRequestReportGroups,
  initRequestReportTemplates,
  initRequestReports,
} from "../actions/reports";
import AppContainer from "./AppContainer";
import { saveToLS } from "../libs/utils";
import { CAPABILITY_LEVELS } from "../constants";
// import { Auth } from "aws-amplify";
// import SideMenu from "./SideMenu";

const drawerWidth = 240;

const useStyles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      overflow: "auto",
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  });

// export const RbacContext = React.createContext();

class LoadingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      progress: 0,
      // rbac: null,
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.initialDataLoad();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.received) this.completeLoading();
  }

  async initialDataLoad() {
    const { properties } = this.props;
    this.setState({ loading: true });
    saveToLS("search_term", "");
    // const user_group = (await Auth.currentSession())
    //   .getIdToken()
    //   .decodePayload()["cognito:groups"][0];
    // const perms = await getRbac(role, user_group);
    await this.props.getUserPermissions();
    await this.props.getUserSettings(this.props.view_id || "All Services");
    await this.props.initGetPortalConfig();
    this.setState({ progress: 20 });

    if (properties.account_id.includes("_SUPER")) {
      await this.props.dispatchChaining(properties.account_id);
      this.setState({ progress: 40 });
    } else {
      await this.props.listDevicesThenAlerts(
        properties.account_id,
        properties.customer
      );
    }

    this.setState({ progress: 60 });

    if (this.props.permissions.reports <= CAPABILITY_LEVELS["view"]) {
      await this.props.initRequestReportTemplates(properties.account_id);
    }

    this.setState({ progress: 80 });
    if (
      this.props.permissions.admin.masquerade <= CAPABILITY_LEVELS["view"] ||
      this.props.permissions.admin.userAccess <= CAPABILITY_LEVELS["manage"] ||
      this.props.permissions.alerts <= CAPABILITY_LEVELS["manage"]
    ) {
      await this.props.getAccountSubgroups(properties.account_id);
      await this.props.getSubDeviceGroups();
    }

    // }

    this.setState({ progress: 100 });
    this.completeLoading();
  }

  /* This is added so the UI hangs at the 100% briefly */
  completeLoading() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  render() {
    var { classes } = this.props;
    const { open, progress } = this.state;

    // if (1) {
    if (this.state.loading === true) {
      return (
        <div
          className={classes.root}
          style={{
            height: "100%",
            position: "absolute",
            left: "0px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <CssBaseline />
          <AppBar position="fixed" className={clsx(classes.appBar)}>
            <Toolbar>
              <Typography variant="h6" noWrap>
                {this.state.title}
              </Typography>
              <img
                style={{ position: "absolute", left: 0, marginLeft: "2%" }}
                src={
                  "https://logic-one.s3.eu-west-2.amazonaws.com/public/LogicOne.svg"
                }
                alt="LogicOne"
                height="28"
              />
            </Toolbar>
          </AppBar>

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <div className="App">
              <Container maxWidth="lg" className={classes.container}>
                <div>
                  <LinearProgressWithLabel
                    style={{ width: "100%" }}
                    value={progress}
                  />
                </div>
                <Typography variant="h2" className="loading_text">
                  Loading Customer Data
                </Typography>
              </Container>
            </div>
          </main>
        </div>
      );
    } else {
      return (
        // <RbacContext.Provider value={rbac}>
        <AppContainer />
        // </RbacContext.Provider>
      );
    }
  }
}

function mapStateToProps(state) {
  const { view_permissions } = state.ui_settings;
  const { login_error_msg, properties, role, view_id } = state.userSession;
  const { byId, received } = state.alerts;
  const { permissions } = state.roles;

  var is_superuser = false;
  // var superuser_views =
  //   view_permissions.AdminMenuItem &&
  //   view_permissions.AdminMenuItem.Permissions &&
  //   JSON.parse(view_permissions.AdminMenuItem.Permissions);

  if (role === "admin" || role === "superuser") {
    // console.log("this user has admin role");
    is_superuser = true;
  } else {
    // console.log("this user does not have admin role");
  }
  // if (superuser_views) {
  //   console.log("this user has admin role");
  //   is_superuser = superuser_views[role];
  // } else {
  //   console.log("this user does not have admin role");
  // }

  return {
    view_permissions,
    login_error_msg,
    properties,
    is_superuser,
    byId,
    received,
    role,
    view_id,
    permissions,
  };
}

const mapDispatchToProps = {
  logoutUser,
  fetchCognitoUser,
  initRequestAllDeviceGroups,
  getSubDeviceGroups,
  getAccountSubgroups,
  listAllDevicesThenAlerts,
  // initMinimalMonitoringSearch,
  initRequestAllDevices,
  listDevicesThenAlerts,
  initRequestAlerts,
  initRequestReportGroups,
  initRequestReportTemplates,
  initRequestReports,
  getViewRoleConfiguration,
  getUserSettings,
  dispatchChaining,
  initGetPortalConfig,
  getUserPermissions,
};

const enhance = compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(LoadingContainer);

export function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

// export default connect(mapStateToProps, mapDispatchToProps)(LoadingContainer)
